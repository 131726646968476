import React, { Component } from 'react'
import Series from './Series-comp'

export default class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <div className="series-container">
          <Series />
        </div>
      </div>
    )
  }
}
