import React, { Component } from 'react'

import Header from './components/Header-comp'
import Homepage from './components/Homepage-comp'
import Footer from './components/Footer-comp'

export default class Main extends Component {
  render() {
    return (
      <div className="container">
        <Header />
        <Homepage />
        <Footer />
      </div>
    )
  }
}
