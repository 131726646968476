import React, { Component } from 'react'
import Logo from '../assets/images/logo/logo.svg'

export default class Banner extends Component {
  render() {
    return (
      <div className="banner-content">
        <img
          src={Logo}
          alt="Logo TV Series"
          aria-label="Logo TV Series"
          tabIndex="0"
        />
      </div>
    )
  }
}
