import React, { Component } from 'react'
import SeriesListDB from '../data/SeriesListDB.json'

export default class Series extends Component {
  render() {
    return (
      <>
        {SeriesListDB &&
          SeriesListDB.map((item) => {
            const year = '(' + item.year + ')'

            return (
              <div className="serie-card" key={item.id}>
                <a
                  href={item.imdb}
                  target="_blank"
                  rel="noopener noreferrer"
                  a={'See on IMDB about this series'}
                  aria-label={'See on IMDB about this series'}
                >
                  <img src={item.img} aria-label={item.serieTitle} />
                </a>
                <ul
                  className="serie-desc"
                  alt="List of seasons and episodes of the series"
                  aria-label="List of seasons and episodes of the series"
                >
                  <li className="serie--title">
                    <span alt="Serie name" aria-label="Serie name">
                      {item.serieTitle}
                    </span>
                    <span
                      className="serie--year"
                      alt="The year of the series"
                      aria-label="The year of the series"
                    >
                      {' '}
                      {year}
                    </span>
                  </li>
                  {item.temporadas.map((temp) => {
                    return (
                      <li key={temp.number}>
                        Session: {temp.number} - Episodes: {temp.episodes}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
      </>
    )
  }
}
